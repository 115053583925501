<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Configuraciones</h4>
            <div class="small text-muted">Configuración de bases y nodos.</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Bases">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="6">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"        
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"                                  
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                      
                    v-if="table.items.length">                       
                
                <template v-slot:row-details="row">
                  <b-card>
                    <b-row>
                      <b-col md="12">
                        <b-row>
                          <b-col md="6">
                            <div class="sensors-base-titulo-nodo mb-2">
                              <b>Base:</b> {{row.item.reference}}
                            </div>
                          </b-col>
                          <b-col md="6">
                            <b-button @click="addSub(row.item)" type="button" variant="secondary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Nodos">
                              <i class="fa fa-plus"></i> Agregar
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="12">
                            <b-table class="mb-0"
                                    responsive="sm"
                                    head-variant="dark"
                                    :hover="true"
                                    :small="true"
                                    :fixed="false"
                                    :sort-by.sync="tableSub.sortBy"
                                    :sort-desc.sync="tableSub.sortDesc"                                      
                                    :items="tableSub.items"
                                    :fields="tableSub.fields"                            
                                    v-if="tableSub.items.length">   
                              
                              <template v-slot:cell(reference)="data">
                                <b>NODO: </b> {{data.item.reference}}
                              </template>

                              <template v-slot:cell(customers)="data">                                
                                <div v-if="data.item.customers">
                                  {{data.item.customers.name}}
                                </div>
                                <div v-else>
                                  --
                                </div>                                
                              </template>           

                              <template v-slot:cell(last_register)="data">
                                <div v-if="data.item.last_register">
                                  {{ moment(data.item.last_register.fecha_hora).format('DD/MM/YYYY') }}
                                </div>
                                <div v-else>
                                  --
                                </div>                                
                              </template>                                                            
                                                              
                              <template v-slot:cell(f_action)="data">
                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                  <b-dropdown-item @click="editSub(data.item)">
                                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="removeSub(data.item)">
                                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                                  </b-dropdown-item>
                                </b-dropdown>
                              </template>

                            </b-table>
                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
                                
                <template v-slot:cell(observations)="data">                  
                  <div class="sensors-base-table-observations" v-if="data.item.nodes.length">
                    <b>Tiene ({{data.item.nodes.length}}) nodos relacionados</b>
                  </div>
                  <div v-else class="text-warning">
                    <b>No tiene nodos relacionados</b>
                  </div>
                </template>
                
                <template v-slot:cell(reference)="data">
                  <b>BASE:</b> {{data.item.reference}}
                </template>

                <template v-slot:cell(customers)="data">
                  <div v-html="getCustomersBase(data.item.nodes)"></div>                                
                </template>     

                <template v-slot:cell(last_register)="data">
                  <div v-html="getLastRegisterBase(data.item.nodes)"></div>                                                  
                </template>                                                            
                                                    
                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="sm">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Referencias">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.form.reference"
                            autofocus
                            placeholder="Ingresar una referencia">
              </b-form-input>
            </b-form-group>
          </b-col>         
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SUB -->      
      <b-modal v-model="modal.formSub.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="sm">
        <div slot="modal-header">
          {{this.modal.formSub.title}}
        </div>

        <b-row>           
          <b-col md="12">
            <b-form-group label="Referencia">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formSub.reference"
                            required
                            placeholder="Ingresar un referencia">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Freq.TX">
              <b-form-input type="number"
                            size="sm"
                            min="0"
                            v-model="crud.formSub.freqtx"
                            required
                            placeholder="Ingresar Freq.TX">
              </b-form-input>            
            </b-form-group>
          </b-col>       
          <!--
          <b-col md="12">
            <b-form-group label="MinManOut">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formSub.min_man_out"
                            required
                            placeholder="Ingresar Min Man Out">
              </b-form-input>            
            </b-form-group>            
          </b-col>                                  
          -->
          <b-col md="12">
            <b-form-group label="Min">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formSub.min"
                            required
                            placeholder="Ingresar Min">
              </b-form-input>            
            </b-form-group>            
          </b-col>                                  
          <b-col md="12">
            <b-form-group label="Max">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formSub.max"
                            required
                            placeholder="Ingresar Max">
              </b-form-input>            
            </b-form-group>            
          </b-col>  
          <b-col md="12">
            <b-form-group label="Aux">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formSub.aux"
                            required
                            placeholder="Ingresar Aux">
              </b-form-input>            
            </b-form-group>            
          </b-col>                                                               
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSub.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSub()">Guardar</b-button>          
        </div>
      </b-modal>      
    </b-col>
  </b-row>
</template>
<script>  
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'  
  import moment from 'moment' 

  export default { 
    data: () => {
      return {      
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configurations',
          elements: {}
        },           
        table : {
          sortBy: 'reference',
          sortDesc: false,           
          items: [],
          fields: [
            {key: 'reference', label: 'Referencia', sortable: true},        
            {key: 'version', label: 'Versión'},
            {key: 'conexion', label: 'Conexión'},
            {key: 'int_senial', label: 'Señal'},
            {key: 'red_id', label: 'Red'},    
            {key: 'consulta', label: 'Consulta'},    
            {key: 'auxiliar', label: 'Auxiliar'},                        
            {key: 'status', label: 'Estado'},                        
            {key: 'last_register', label: 'Último Registro', class: 'text-center'},
            {key: 'customers', label: 'Cliente'},
            {key: 'observations', label: 'Observaciones'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        tableSub : {
          sortBy: 'reference',
          sortDesc: false,          
          items: [],
          fields: [
            {key: 'reference', label: 'Referencia', sortable: true,},            
            {key: 'freqtx', label: 'Freqtx', class: 'text-center'},            
            //{key: 'min_man_out', label: 'MinManOut', class: 'text-center'},                        
            {key: 'min', label: 'Min', class: 'text-center'},
            {key: 'max', label: 'Max', class: 'text-center'},
            {key: 'aux', label: 'Aux', class: 'text-center'},            
            {key: 'last_register', label: 'Último Registro', class: 'text-center'},
            {key: 'customers', label: 'Clientes', class: 'text-left'},
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },        
        crud: {
          form: {
            id: 0,
            reference: 0,            
          }, 
          formSub: {
            id: 0,
            base_id: 0,
            base: null,
            reference: 0,            
            freqtx: 1,
            aux: 0,
            //min_man_out: 0,
            min: 0,
            max: 0,
          },                    
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formSub: {
            active: false,
            title: ''
          },          
        },
        arr: {
          bases: [],          
        },                
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.load()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {      
        this.arr.bases.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.load()
      },       
      load() {
        var result = serviceAPI.obtenerBase()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.bases = data

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true       
            
            if(this.table.items[this.table.rowSelected].nodes) {
              this.tableSub.items = this.table.items[this.table.rowSelected].nodes
            }
          }           

        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.reference = 0
        
        this.modal.form.title = "Nuevo Base"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.reference = item.reference
                 
        this.modal.form.title = "Editar Base"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.reference = item.reference   

        this.$bvModal.msgBoxConfirm('¿Desea borrar la base con referencia '+ this.crud.form.reference + '?', {
          title: 'Borrar Base',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarBase(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarBase(this.crud.form);
        } else {
          var result = serviceAPI.agregarBase(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      getCustomersBase(nodes) {
        if(nodes.length>0) {
          var customers = nodes[0].customers
          var status = true
        
          nodes.forEach(element => {            
            if(customers && element.customers) {
              if(customers.id != element.customers.id) {
                status = false
              }            
            } else {
              status = false
            }
          });

          if(status) {
            return customers.name
          } else {
            return '<alert class="alert alert-danger p-0">REVISAR CLIENTES EN NODO</alert>'
          }
        } else {
          return "--";
        }
      },
      getLastRegisterBase(nodes) {           
        if(nodes.length>0) {          
          var last_register = nodes[0].last_register
                  
          nodes.forEach(element => {            
            if(last_register && element.last_register) {
              if(last_register.fecha_hora < element.last_register.fecha_hora) {
                last_register = element.last_register
              }            
            } else {
              last_register = null
            }
          });

          if(last_register) {
            return moment(last_register.fecha_hora).format('DD/MM/YYYY')
          } else {
            return "--";  
          }          
        } else {
          return "--";
        }
      },

      addSub(item) {
        this.crud.formSub.id = 0
        this.crud.formSub.base_id = item.id
        this.crud.formSub.reference = 0
        this.crud.formSub.freqtx = 1
        this.crud.formSub.aux = 0
        //this.crud.formSub.min_man_out = 0
        this.crud.formSub.min = 0
        this.crud.formSub.max = 0
        this.modal.formSub.title = "Nuevo Nodo"
        this.modal.formSub.active = true
      },
      editSub(item) { 
        this.crud.formSub.id = item.id
        this.crud.formSub.base_id = item.base_id
        this.crud.formSub.reference = item.reference
        this.crud.formSub.freqtx = item.freqtx
        this.crud.formSub.aux = item.aux
        //this.crud.formSub.min_man_out = item.min_man_out    
        this.crud.formSub.min = item.min
        this.crud.formSub.max = item.max    
        this.modal.formSub.title = "Editar Nodo"
        this.modal.formSub.active = true
      },
      removeSub(item) {
        this.crud.formSub.id = item.id
        this.crud.formSub.reference = item.reference                   

        this.$bvModal.msgBoxConfirm('¿Desea borrar el nodo '+ this.crud.formSub.reference + '?', {
          title: 'Borrar el nodo',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarBaseNode(this.crud.formSub.id);

            result.then((response) => {      
              this.modal.formSub.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveSub() {
        /*
        if(this.crud.formSub.freqtx<1 || this.crud.formSub.freqtx>4){
          this.$awn.alert("El campo freqtx debe ser un valor entre 1 y 4");
          return false
        }
        */

        let loader = this.$loading.show();
        if (this.crud.formSub.id) {
          var result = serviceAPI.editarBaseNode(this.crud.formSub);
        } else {
          var result = serviceAPI.agregarBaseNode(this.crud.formSub);
        }

        result.then((response) => {
          this.modal.formSub.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },      
    }    
  }
</script>
<style>
  .sensors-base-titulo-nodo {
    font-size: 17px;
  }
  .sensors-base-table-observations {
    color: darkblue;
  }
</style>